import React, { useState } from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { FaEnvelope, FaPhoneAlt, FaFacebook } from "react-icons/fa"
import {
  Button,
  Column,
  Columns,
  ContactForm,
  Hero,
  Layout,
  LeafletMap,
  Modal,
  Seo,
  TextContent,
} from "../components"
import * as textStyles from "../components/text-content.module.scss"

export default function Contact({ data }) {
  var [modalOpen, setModalOpen] = useState(false)
  var heroImage = getImage(data.file)
  var title = "Contact"

  return (
    <Layout site="audio" page={`/`}>
      <Seo title={title} shop="audio" />
      <Hero image={heroImage} site="audio">
        Wim's Hoorcenter
      </Hero>
      <TextContent title={title} span="We helpen je graag verder!">
        <div className={textStyles.paragraph}>
          <Columns responsive={true}>
            <Column style={{ marginRight: "3rem" }}>
              <h4>Wim's Hoorcenter</h4>
              <p className={textStyles.paragraph}>
                <FaPhoneAlt /> <a href="tel:+32474358313">0474/358.313</a>{" "}
                <br />
                <FaEnvelope />{" "}
                <a href="mailto:contact@wimshoorcenter.be">
                  contact@wimshoorcenter.be
                </a>{" "}
                <br />
                <FaFacebook />{" "}
                <a
                  href="https://www.facebook.com/wimshoorcenter.be"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </p>
              <p className={textStyles.paragraph}>
                Pallieterweidestraat 16 <br />
                1501 Halle
              </p>
              <p className={textStyles.paragraph}>
                <small>Parkeergelegenheid in de straat.</small> <br />
                <small>
                  Makkelijk toegankelijk voor minder mobiele patiënten.
                </small>
              </p>
            </Column>
            <Column style={{ marginRight: "3rem" }}>
              <h4>Uitsluitend open op afspraak</h4>
              <Button site="audio" onClick={() => setModalOpen(true)}>
                Maak uw afspraak
              </Button>
            </Column>
          </Columns>
        </div>
        <div className={textStyles.paragraph}>
          <LeafletMap
            center={[50.74945, 4.25993]}
            zoom={14}
            popup="Wim's Hoorcenter"
          />
        </div>
      </TextContent>
      <Modal
        state={modalOpen}
        close={() => setModalOpen(false)}
        header="Maak uw afspraak"
      >
        <ContactForm />
      </Modal>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "contact-audio" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
